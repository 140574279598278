.about{
	position: relative;
}

.aboutContent{
	text-align: left;
}
.aboutTitle{
	text-align: left;
	/*border-bottom: 1px solid black;*/
}