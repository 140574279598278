.basicInfo
{
	text-align:center;
  left: 50%;
}
.extraClass {
 max-width:150px;
 font-size: 20px !important;
 pointer-events: auto !important;
&:hover {
visibility: visible !important;
opacity: 1 !important;
 }
}

.caption{
  font-size: 20px;
  text-align:center;
  color: #f3f3f3;
  cursor: pointer;
}
.enter-items:hover{
    cursor:pointer; 
}
.inner-items:hover{
    cursor:pointer; 
}
